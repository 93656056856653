// extracted by mini-css-extract-plugin
export var bulletPoints = "campuses-module--bullet-points--oJxUP";
export var campusBreakdown = "campuses-module--campus-breakdown--MuDF5";
export var campusCaption = "campuses-module--campus-caption--VBUQY";
export var campusContent = "campuses-module--campus-content--zyDD8";
export var campusLocation = "campuses-module--campus-location--p49Tf";
export var campusOverview = "campuses-module--campus-overview--7EYmP";
export var campuses = "campuses-module--campuses--HDxQ2";
export var hero = "campuses-module--hero--bqqcV";
export var individualCampus = "campuses-module--individual-campus--ELtCC";
export var locationalLinks = "campuses-module--locational-links--SdMg4";
export var mainContent = "campuses-module--main-content--SFYWO";
export var sidebar = "campuses-module--sidebar--vhHTB";
export var sidebarTitle = "campuses-module--sidebar-title--kPkhI";
export var title = "campuses-module--title--N66qG";